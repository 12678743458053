import { Check, Clock, Send } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getExtendedPledgeTasks } from '../../api/api';
import ModalView from '../../components/_shared/ModalView';
import { GetDurationInDays } from '../../utils/parseDuration';
import clsx from 'clsx';
import { ExtendSigningDeadline } from '../tasks/ExtendSigningDeadline/ExtendSigningDeadline';
import { SendReminderEmail } from '../tasks/SendReminderEmail/SendReminderEmail';

const DocumentStatus = ({
  signee,
  documents,
  index,
  activeSignee,
  setActiveSignee,
  flowProps,
}) => {
  const signeesTask = documents.flatMap((doc) => {
    const signingTasks = doc.signing?.signingTasks.filter(
      (sign) => sign.nationalId === signee.nationalId,
    );

    return {
      signeeStatus: signingTasks?.[0],
      document: doc?.document,
    };
  });

  const signeesTaskLength = signeesTask.filter(
    (text) => text.signeeStatus?.archiveId,
  );

  const { t } = flowProps;

  const { NODE_ENV } = process.env;
  console.debug('env is', NODE_ENV);

  const hasSignedAll = signeesTaskLength.length === signeesTask.length;

  const queryClient = useQueryClient();
  useEffect(() => {
    if (hasSignedAll && activeSignee === index) {
      setActiveSignee(activeSignee + 1);
    }
  }, [activeSignee, signeesTaskLength]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['getExtendedPledgeTasks', flowProps?.caseData?.flowId],
    });
  }, [signeesTaskLength.length, activeSignee]);

  const {
    data: tasks,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['getExtendedPledgeTasks', flowProps?.caseData?.flowId],
    queryFn: async () =>
      await getExtendedPledgeTasks(flowProps?.caseData?.flowId),
  });

  const [displayExtendTask, setDisplayExtendTask] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [sendReminderIsSubmitting, sendReminderSetIsSubmitting] =
    useState(false);
  const [displaySendReminderTask, setDisplaySendReminderTask] = useState(false);

  if (tasks) {
    const dataBySignee = tasks.filter(
      (task) => task?.variables?.signee?.id === signee.id,
    );
    const firstSigningTask = tasks?.find(
      (sign) =>
        signeesTask?.find(
          (task) =>
            task.signeeStatus?.signUrl === sign.variables?.signingTask?.url,
        ),
    );
    const timeLeft = Number(
      GetDurationInDays(
        firstSigningTask?.variables?.signingStartTime,
        firstSigningTask?.variables?.deadline,
      ),
    );
    const timeLeftString =
      timeLeft && `${timeLeft} ${t('days-left', 'dager igjen')}`;

    return (
      <>
        <div className="flex items-center gap-4">
          <span className="capitalize text-lg font-medium">
            {signee.name.toLowerCase()}
          </span>
          {activeSignee === index && signee.id && ( // hide buttons for old cases, flowProps not available there
            <div className="flex items-center gap-2 text-xs">
              <button
                onClick={() => setDisplayExtendTask(true)}
                className="bg-white text-blue-500 py-1 px-2 font-bold border-blue-200 border rounded-md flex items-center gap-2 hover:bg-blue-500 hover:text-white"
              >
                {t('extend-due-date', 'Utsett frist')}
                <Clock className="h-5 w-5" />
              </button>

              <button
                onClick={() => setDisplaySendReminderTask(true)}
                className={`py-1 px-2 font-bold rounded-md border flex gap-2 items-center bg-white text-blue-500 border-blue-200 hover:bg-blue-500 hover:text-white`}
              >
                {t('send-reminder', 'Send påminnelse')}
                <Send className="h-5 w-5" />
              </button>

              {!!timeLeft ? (
                <span
                  className={clsx('italic', timeLeft <= 3 && 'text-red-500')}
                >
                  {timeLeftString}
                </span>
              ) : null}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          {signeesTask.map((signeeData) => {
            if (index < activeSignee) {
              return (
                <div className="flex flex-row items-center gap-4">
                  <div className="text-green-500" title="Signert">
                    <Check className="h-5 w-5" />
                  </div>

                  <span>
                    {signeeData.document.outputFilename.replace('.pdf', '')}
                  </span>
                </div>
              );
            }
            if (index > activeSignee) {
              return (
                <div className="flex flex-row items-center gap-4 opacity-30">
                  <span>
                    {signeeData.document.outputFilename.replace('.pdf', '')}
                  </span>
                </div>
              );
            }
            return (
              <div className="flex flex-row items-center gap-4">
                {signeeData.signeeStatus?.archiveId ? (
                  <div className="text-green-500" title="Signert">
                    <Check className="h-5 w-5" />
                  </div>
                ) : (
                  <div className="text-yellow-500" title="Venter på signatur">
                    <Clock className="h-5 w-5" />
                  </div>
                )}
                <span>
                  {signeeData.document.outputFilename.replace('.pdf', '')}
                </span>
                {['development', 'test'].includes(NODE_ENV) && (
                  <a
                    target="_blank"
                    href={signeeData?.signeeStatus?.signUrl}
                    className="underline color-blue-500"
                    rel="noreferrer"
                  >
                    Link
                  </a>
                )}
              </div>
            );
          })}
        </div>
        {displayExtendTask && tasks && (
          <ModalView
            title={`${t('extend-due-date', 'Utvid signeringsfrist')}`}
            message={''}
            isConfirming={isSubmitting}
            onConfirm={() => null}
            props={dataBySignee[0]}
            customHeight="50%"
            customWidth="40%"
            component={ExtendSigningDeadline({
              data: dataBySignee[0],
              allMessageTasks: dataBySignee,
              currentTasks: signeesTask,
              signee,
              t,
              setOpen: setDisplayExtendTask,
            })}
            onAbort={() => setDisplayExtendTask(false)}
            onClose={() => setDisplayExtendTask(false)}
          />
        )}
        {displaySendReminderTask && tasks && (
          <ModalView
            title={`${t('send-reminder', 'Send påminnelse')}`}
            message={''}
            isConfirming={sendReminderIsSubmitting}
            onConfirm={() => null}
            props={dataBySignee[0]}
            customHeight="50%"
            customWidth="40%"
            component={
              <SendReminderEmail
                data={dataBySignee[0]}
                t={t}
                setOpen={setDisplaySendReminderTask}
                refetch={refetch}
                signee={signee}
              />
            }
            onAbort={() => setDisplaySendReminderTask(false)}
            onClose={() => setDisplaySendReminderTask(false)}
          />
        )}
      </>
    );
  }
  return <div>Fetching signee data...</div>;
};

export default DocumentStatus;
