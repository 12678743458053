import { queueNames } from './queueNames';
import { User } from '../../../types/user';
import { Role } from '../../../types/role';

const baseQueue = {
  queueSort: 'sort=createdAt&dir=-1',
  limit: 20,
  pollingInterval: 30_000,
  pollingEnabled: false,
};

export function queueDefinition(user: User) {
  const baseQuery = (status = 'active') =>
    `?status=${status}&flowDefinitionId=case&view=caseSummary`;
  return {
    [queueNames.MY_CASES]: {
      name: queueNames.MY_CASES,
      queueType: 'assignee',
      queueFilter: `${baseQuery()}`,
      label: 'my-cases-label',
      ...baseQueue,
    },
    [queueNames.TINGLYSING]: {
      name: queueNames.CASES,
      queueFilter: `${baseQuery()}`,
      label: 'active-cases-label',
      ...baseQueue,
    },
    [queueNames.TERMINATED_CASES]: {
      name: queueNames.TERMINATED_CASES,
      queueFilter: `${baseQuery('archived')}`,
      ...baseQueue,
    },
    [queueNames.FAILED_CASES]: {
      name: queueNames.FAILED_CASES,
      queueFilter: `${baseQuery()}&hasIncidents=true`,
      ...baseQueue,
    },
    [queueNames.COMPLETED_CASES]: {
      name: queueNames.COMPLETED_CASES,
      queueFilter: `${baseQuery('completed')}`,
      ...baseQueue,
    },
    [queueNames.AWAITING_CONFIGURE_SIGNEES]: {
      name: queueNames.AWAITING_CONFIGURE_SIGNEES,
      queueFilter: `${baseQuery()}&data.queue=CONFIGURE_SIGNEES`,
      ...baseQueue,
    },
    [queueNames.AWAITING_ADD_PLEDGE_INFORMATION]: {
      name: queueNames.AWAITING_ADD_PLEDGE_INFORMATION,
      queueFilter: `${baseQuery()}&data.queue=ADD_PLEDGE_INFORMATION`,
      ...baseQueue,
    },
    [queueNames.AWAITING_DOCUMENT_CONTROL]: {
      name: queueNames.AWAITING_DOCUMENT_CONTROL,
      queueFilter: `${baseQuery()}&data.queue=DOCUMENT_CONTROL`,
      ...baseQueue,
    },
    [queueNames.AWAITING_DOCUMENT_CONTROL_REGISTRATION]: {
      name: queueNames.AWAITING_DOCUMENT_CONTROL_REGISTRATION,
      queueFilter: `${baseQuery()}&data.queue=DOCUMENT_CONTROL_REGISTRATION`,
      ...baseQueue,
    },
    [queueNames.AWAITING_SIGNING_DOCUMENTS]: {
      name: queueNames.AWAITING_SIGNING_DOCUMENTS,
      queueFilter: `${baseQuery()}&data.queue=SIGNING_DOCUMENTS`,
      ...baseQueue,
    },
    [queueNames.AWAITING_REGISTRATION]: {
      name: queueNames.AWAITING_REGISTRATION,
      queueFilter: `${baseQuery()}&data.queue=REGISTRATION`,
      ...baseQueue,
    },
    [queueNames.EXPIRED_CASES]: {
      name: queueNames.EXPIRED_CASES,
      queueFilter: `${baseQuery('completed')}&data.status=expired`,
      ...baseQueue,
    },
    [queueNames.AWAITING_DOWNLOAD_FILES_FOR_ARCHIVING]: {
      name: queueNames.AWAITING_DOWNLOAD_FILES_FOR_ARCHIVING,
      queueFilter: `${baseQuery()}&data.queue=DOWNLOAD_FILES_FOR_ARCHIVING`,
      ...baseQueue,
    },
  };
}
